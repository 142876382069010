// src/components/Sidebar.jsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import { HomeIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';

const Sidebar = () => {
  return (
    <aside className="w-64 h-screen px-3 py-4 overflow-y-auto bg-indigo-50 shadow-md hidden md:block">
      <ul className="space-y-2">
        <li>
          <NavLink 
            to="/dashboard" 
            className={({ isActive }) =>
              `flex items-center p-2 text-base font-normal rounded-lg ${
                isActive ? 'bg-indigo-200 text-indigo-700' : 'text-gray-900 hover:bg-indigo-100'
              }`
            }
          >
            <HomeIcon className="w-6 h-6 text-indigo-500" />
            <span className="ml-3">Anasayfa</span>
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/setup" 
            className={({ isActive }) =>
              `flex items-center p-2 text-base font-normal rounded-lg ${
                isActive ? 'bg-indigo-200 text-indigo-700' : 'text-gray-900 hover:bg-indigo-100'
              }`
            }
          >
            <Cog6ToothIcon className="w-6 h-6 text-indigo-500" />
            <span className="ml-3">Kurulum</span>
          </NavLink>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
