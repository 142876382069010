// src/pages/Setup.jsx
import React, { useState } from 'react';
import { useAuth } from '../AuthContext';
import { doc, setDoc } from 'firebase/firestore';
import { memberDb } from '../firebase/memberFirebase';
import { useNavigate } from 'react-router-dom';

const Setup = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [firebaseConfig, setFirebaseConfig] = useState({
    apiKey: '',
    authDomain: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    databaseURL: '' // Realtime Database URL için ekleme
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFirebaseConfig({
      ...firebaseConfig,
      [e.target.name]: e.target.value
    });
  };

  const handleSetup = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    // Alanların boş olmadığını kontrol et
    const allFieldsFilled = Object.values(firebaseConfig).every((field) => field.trim() !== '');
    if (!allFieldsFilled) {
      setError('Lütfen tüm alanları doldurun.');
      setLoading(false);
      return;
    }

    try {
      // Kullanıcının Firestore belgesine firebaseConfig'i ve setupCompleted durumunu kaydet
      const userDocRef = doc(memberDb, 'users', currentUser.uid);
      await setDoc(userDocRef, {
        firebaseConfig,
        setupCompleted: true // Kurulum tamamlandı olarak işaretle
      }, { merge: true }); // Mevcut verileri korur

      console.log('Kurulum başarıyla tamamlandı.');
      navigate('/dashboard'); // Başarılı kurulumdan sonra Dashboard'a yönlendir
    } catch (err) {
      console.error('Kurulum Hatası:', err);
      setError('Kurulum sırasında bir hata oluştu. Lütfen tekrar deneyin.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form onSubmit={handleSetup} className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Firebase Kurulumu</h2>
        
        {error && (
          <div className="mb-4 text-red-600 text-sm text-center">
            {error}
          </div>
        )}

        {/* Firebase Config Inputları */}
        <div className="mb-4">
          <label className="block mb-1 text-sm font-medium text-gray-700">API Key</label>
          <input 
            type="text" 
            name="apiKey"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
            value={firebaseConfig.apiKey}
            onChange={handleChange}
            required
            placeholder="API Key"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-1 text-sm font-medium text-gray-700">Auth Domain</label>
          <input 
            type="text" 
            name="authDomain"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
            value={firebaseConfig.authDomain}
            onChange={handleChange}
            required
            placeholder="Auth Domain"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-1 text-sm font-medium text-gray-700">Project ID</label>
          <input 
            type="text" 
            name="projectId"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
            value={firebaseConfig.projectId}
            onChange={handleChange}
            required
            placeholder="Project ID"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-1 text-sm font-medium text-gray-700">Storage Bucket</label>
          <input 
            type="text" 
            name="storageBucket"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
            value={firebaseConfig.storageBucket}
            onChange={handleChange}
            required
            placeholder="Storage Bucket"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-1 text-sm font-medium text-gray-700">Messaging Sender ID</label>
          <input 
            type="text" 
            name="messagingSenderId"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
            value={firebaseConfig.messagingSenderId}
            onChange={handleChange}
            required
            placeholder="Messaging Sender ID"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-1 text-sm font-medium text-gray-700">App ID</label>
          <input 
            type="text" 
            name="appId"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
            value={firebaseConfig.appId}
            onChange={handleChange}
            required
            placeholder="App ID"
          />
        </div>

        {/* Realtime Database URL Input */}
        <div className="mb-6">
          <label className="block mb-1 text-sm font-medium text-gray-700">Realtime Database URL</label>
          <input 
            type="text" 
            name="databaseURL"
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
            value={firebaseConfig.databaseURL}
            onChange={handleChange}
            required
            placeholder="https://your-database.firebaseio.com"
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          className={`w-full py-2 px-4 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {loading ? 'Kurulum Yapılıyor...' : 'Kurulum Yap'}
        </button>
      </form>
    </div>
  );
};

export default Setup;
