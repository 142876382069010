// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AuthLayout from './components/layouts/AuthLayout';
import MainLayout from './components/layouts/MainLayout';
import Login from './pages/Login';
import Dashboard from './pages/CustomerDashboard';
import Setup from './pages/Setup';
import PrivateRoute from './components/PrivateRoute';
import DetailsPage from './pages/Details';

function App() {
  return (
    <Router>
      <Routes>
        {/* Korumasız Rotalar */}
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
        </Route>

        {/* Korunan Rotalar */}
        <Route element={<PrivateRoute />}>
          <Route element={<MainLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/setup" element={<Setup />} />
            <Route path="/details/:id" element={<DetailsPage />} />
          </Route>
        </Route>


        {/* Tüm Diğer Yolları Login'e Yönlendir */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
