import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { ref, onValue } from 'firebase/database';
import { initializeCustomerFirebase } from '../firebase/customerFirebase';
import { memberDb } from '../firebase/memberFirebase';

const DetailsPage = () => {
  const { id } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [perons, setPerons] = useState({});
  const [cumulativeData, setCumulativeData] = useState({});
  const [editableNames, setEditableNames] = useState({});
  const [editingField, setEditingField] = useState(null); // Düzenlenen alan
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDetails = async () => {
      if (!currentUser) {
        navigate('/login');
        return;
      }

      try {
        const userDocRef = doc(memberDb, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          console.error('Kullanıcı belgesi bulunamadı.');
          navigate('/setup');
          return;
        }

        const userData = userDoc.data();

        if (!userData.setupCompleted || !userData.firebaseConfig) {
          console.error('Setup tamamlanmamış veya Firebase Config eksik.');
          navigate('/setup');
          return;
        }

        const { customerRealtimeDb, customerDb } =
          initializeCustomerFirebase(userData.firebaseConfig);

        const nodeRef = ref(customerRealtimeDb, `${id}`);
        onValue(
          nodeRef,
          async (snapshot) => {
            const data = snapshot.val();
            if (data) {
              console.log('Realtime Database data received:', data);
              setPerons(data);

              const cumulativeDocRef = doc(customerDb, 'cumulativeData', id);
              const cumulativeDoc = await getDoc(cumulativeDocRef);
              let updatedCumulativeData = cumulativeDoc.exists()
                ? cumulativeDoc.data()
                : {};

              Object.entries(data).forEach(([peronKey, peronData]) => {
                Object.entries(peronData).forEach(([inputKey, inputValue]) => {
                  if (inputKey.startsWith('Input')) {
                    const key = `${peronKey}-${inputKey}`;
                    updatedCumulativeData[key] =
                      (updatedCumulativeData[key] || 0) + (inputValue || 0);
                  }
                });
              });

              await setDoc(cumulativeDocRef, updatedCumulativeData, {
                merge: true,
              });
              setCumulativeData(updatedCumulativeData);

              const namesDocRef = doc(customerDb, 'nodeNames', id);
              const namesDoc = await getDoc(namesDocRef);

              if (!namesDoc.exists()) {
                const defaultNames = {};
                Object.keys(data).forEach((peronKey) => {
                  defaultNames[peronKey] = peronKey;
                  Object.keys(data[peronKey]).forEach((inputKey) => {
                    defaultNames[`${peronKey}-${inputKey}`] = inputKey;
                  });
                });
                await setDoc(namesDocRef, defaultNames);
                setEditableNames(defaultNames);
              } else {
                setEditableNames(namesDoc.data());
              }
            }
            setLoading(false);
          },
          (error) => {
            console.error('Realtime Database Okuma Hatası:', error);
            setLoading(false);
          }
        );
      } catch (error) {
        console.error('Hata:', error);
        setLoading(false);
        navigate('/setup');
      }
    };

    fetchDetails();
  }, [currentUser, id, navigate]);

  const handleNameChange = async (key, newName) => {
    try {
      const { customerDb } = initializeCustomerFirebase(
        (await getDoc(doc(memberDb, 'users', currentUser.uid))).data()
          .firebaseConfig
      );
      const namesDocRef = doc(customerDb, 'nodeNames', id);

      await updateDoc(namesDocRef, {
        [key]: newName,
      });

      setEditableNames((prev) => ({
        ...prev,
        [key]: newName,
      }));
    } catch (error) {
      console.error('Ad Güncelleme Hatası:', error);
    }
  };

  const handleFieldBlur = (key, value) => {
    setEditingField(null);
    if (value.trim() !== '') {
      handleNameChange(key, value.trim());
    }
  };

  const calculateTotalForPeron = (peronKey) => {
    return Object.entries(cumulativeData)
      .filter(([key]) => key.startsWith(`${peronKey}-`))
      .reduce((sum, [, value]) => sum + value, 0);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p>Detaylar yükleniyor...</p>
      </div>
    );
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">{id} Şube Detayları</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {Object.entries(perons).map(([peronKey, peronData]) => {
          const totalForPeron = calculateTotalForPeron(peronKey);

          return (
            <div
              key={peronKey}
              className="p-6 bg-white rounded-lg shadow-md border"
            >
              {editingField === peronKey ? (
                <input
                  type="text"
                  className="text-xl font-bold mb-2 border border-gray-300 rounded p-1 w-full"
                  defaultValue={editableNames[peronKey] || peronKey}
                  autoFocus
                  onBlur={(e) => handleFieldBlur(peronKey, e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleFieldBlur(peronKey, e.target.value);
                    }
                  }}
                />
              ) : (
                <h2
                  className="text-xl font-bold mb-2 text-gray-800 cursor-pointer"
                  onDoubleClick={() => setEditingField(peronKey)}
                >
                  {editableNames[peronKey] || peronKey}
                </h2>
              )}

              <p className="text-lg text-gray-600">
                Toplam Birikmiş:{" "}
                <span className="font-semibold">{totalForPeron}</span>
              </p>

              <div className="mt-4 space-y-2">
                {Object.entries(peronData).map(([inputKey, inputValue]) => (
                  <div
                    key={inputKey}
                    className="p-4 bg-gray-100 rounded shadow-md"
                  >
                    {editingField === `${peronKey}-${inputKey}` ? (
                      <input
                        type="text"
                        className="text-lg font-bold border border-gray-300 rounded p-1 w-full"
                        defaultValue={
                          editableNames[`${peronKey}-${inputKey}`] || inputKey
                        }
                        autoFocus
                        onBlur={(e) =>
                          handleFieldBlur(
                            `${peronKey}-${inputKey}`,
                            e.target.value
                          )
                        }
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleFieldBlur(
                              `${peronKey}-${inputKey}`,
                              e.target.value
                            );
                          }
                        }}
                      />
                    ) : (
                      <h3
                        className="text-lg font-bold text-gray-700 cursor-pointer"
                        onDoubleClick={() =>
                          setEditingField(`${peronKey}-${inputKey}`)
                        }
                      >
                        {editableNames[`${peronKey}-${inputKey}`] || inputKey}
                      </h3>
                    )}
                    <p className="text-gray-600">
                      Son Gelen:{" "}
                      {typeof inputValue === "number" ? inputValue : 0}
                    </p>
                    <p className="text-gray-600">
                      Birikmiş:{" "}
                      {typeof cumulativeData[`${peronKey}-${inputKey}`] ===
                      "number"
                        ? cumulativeData[`${peronKey}-${inputKey}`]
                        : 0}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DetailsPage;
