// src/pages/Login.jsx
import React, { useState } from 'react';
import { 
  signInWithEmailAndPassword, 
  signInWithPopup, 
  GoogleAuthProvider, 
  GithubAuthProvider 
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { memberAuth } from '../firebase/memberFirebase';
import { getFirestore, doc, getDoc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const db = getFirestore(); // Firestore instance

  const handleLogin = async (loginType) => {
    setLoading(true);
    setError(null);
    try {
      let userCredential;
      if (loginType === 'email') {
        userCredential = await signInWithEmailAndPassword(memberAuth, email, password);
      } else if (loginType === 'google') {
        const provider = new GoogleAuthProvider();
        userCredential = await signInWithPopup(memberAuth, provider);
      } else if (loginType === 'github') {
        const provider = new GithubAuthProvider();
        userCredential = await signInWithPopup(memberAuth, provider);
      }

      const user = userCredential.user;

      // Firestore'da kullanıcı belgesini al veya oluştur
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        // Belge mevcut, güncelle
        await updateDoc(userDocRef, {
          lastLogin: serverTimestamp()
        });
      } else {
        // Belge mevcut değil, oluştur
        await setDoc(userDocRef, {
          email: user.email,
          role: 'customer', // Varsayılan rol 'customer'
          createdAt: serverTimestamp(),
          lastLogin: serverTimestamp(),
          // Sosyal girişlerde ek bilgiler ekleyebilirsiniz
          displayName: user.displayName || '',
          photoURL: user.photoURL || '',
        });
      }

      // Kullanıcının rolünü al
      const updatedUserDoc = await getDoc(userDocRef);
      const userData = updatedUserDoc.data();
      const role = userData.role; // 'customer', 'distributor', diğer rolleri daha sonra ekleyecek

      // Rol bazlı yönlendirme
      if (role === 'customer') {
        navigate('/dashboard');
      } else if (role === 'distributor') {
        navigate('/distributor-dashboard');
      } else {
        // Tanımlanmamış roller için varsayılan yönlendirme
        console.warn(`Tanımlanmamış rol: ${role}`);
        navigate('/dashboard');
      }

    } catch (error) {
      console.error("Login Hatası:", error);
      setError(`Giriş başarısız: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col lg:flex-row w-full max-w-6xl bg-white shadow-lg rounded-lg overflow-hidden">
      {/* Sol Kısım - Login Formu */}
      <div className="w-full lg:w-1/2 p-8 md:p-12">
        <div className="mb-8 text-center">
          <img 
            src="https://storage.googleapis.com/devitary-image-host.appspot.com/15846435184459982716-LogoMakr_7POjrN.png" 
            className="w-32 mx-auto" 
            alt="Logo" 
          />
        </div>
        <h1 className="text-2xl font-extrabold text-gray-900 mb-6 text-center">Giriş Yap</h1>
        
        {/* Hata Mesajı */}
        {error && (
          <div className="mb-4 text-red-500 text-center">
            {error}
          </div>
        )}

        {/* Email ile Giriş Formu */}
        <form onSubmit={(e) => { e.preventDefault(); handleLogin('email'); }}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input 
              type="email" 
              className="w-full px-4 py-2 mt-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700">Şifre</label>
            <input 
              type="password" 
              className="w-full px-4 py-2 mt-1 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
            />
          </div>
          <button 
            type="submit" 
            className="w-full px-4 py-2 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600"
            disabled={loading}
          >
            {loading ? 'Giriş Yapılıyor...' : 'Giriş Yap'}
          </button>
        </form>

        {/* Sosyal Giriş Butonları */}
        <div className="mt-6 space-y-4">
          <button 
            onClick={() => handleLogin('google')}
            className="w-full px-4 py-2 text-gray-700 bg-gray-100 rounded-lg flex items-center justify-center hover:bg-gray-200 focus:outline-none"
            disabled={loading}
          >
            <svg className="w-5 h-5 mr-2" viewBox="0 0 533.5 544.3">
              <path
                d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                fill="#4285f4"
              />
              <path
                d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                fill="#34a853"
              />
              <path
                d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                fill="#fbbc04"
              />
              <path
                d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                fill="#ea4335"
              />
            </svg>
            Google ile Giriş Yap
          </button>
          <button 
            onClick={() => handleLogin('github')}
            className="w-full px-4 py-2 text-gray-700 bg-gray-100 rounded-lg flex items-center justify-center hover:bg-gray-200 focus:outline-none"
            disabled={loading}
          >
            <svg className="w-5 h-5 mr-2" viewBox="0 0 32 32">
              <path fillRule="evenodd" d="M16 4C9.371 4 4 9.371 4 16c0 5.3
              
              3.438 9.8 8.207 11.387.602.11.82-.258.82-.578 0-.286-.011-1.04-.015-2.04-3.34.723-4.043-1.609-4.043-1.609-.547-1

              .387-1.332-1.758-1.332-1.758-1.09-.742.082-.726.082-.726 1.203.086 1.836 1.234 1.836 1.234 1.07 1.836 2.808 1.305 3.492 1 .11-.777.422-1.305.762-1.605-2.664-.301-5.465-1.332-5.465-5.93 0-1.313.469-2.383 1.234-3.223-.121-.3-.535-1.523.117-3.175 0 0 1.008-.32 3.301 1.23A11.487 11.487 0 0116 9.805c1.02.004 2.047.136 3.004.402 2.293-1.55 3.297-1.23 3.297-1.23.656 1.652.246 2.875.12 3.175.77.84 1.231 1.91 1.231 3.223 0 4.61-2.804 5.621-5.476 5.922.43.367.812 1.101.812 2.219 0 1.605-.011 2.898-.011 3.293 0 .32.214.695.824.578C24.566 25.797 28 21.3 28 16c0-6.629-5.371-12-12-12z" />
            </svg>
            GitHub ile Giriş Yap
          </button>
        </div>

        {/* Divider */}
        <div className="my-6 border-b text-center">
          <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
            Veya email ile giriş yapın
          </div>
        </div>

        {/* Kullanıcı Sözleşmeleri */}
        <p className="mt-6 text-xs text-gray-600 text-center">
          Temel Hizmetlerimizi ve Gizlilik Politikamızı kabul ediyorum.
          <a href="#" className="border-b border-gray-500 border-dotted ml-1">
            Şartlar ve Koşullar
          </a>
          ve 
          <a href="#" className="border-b border-gray-500 border-dotted ml-1">
            Gizlilik Politikası
          </a>
        </p>
      </div>

      {/* Sağ Kısım - Görsel */}
      <div className="flex-1 bg-indigo-100 text-center hidden lg:flex">
        <div 
          className="m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat"
          style={{ backgroundImage: "url('https://storage.googleapis.com/devitary-image-host.appspot.com/15848031292911696601-undraw_designer_life_w96d.svg')" }}
        >
        </div>
      </div>
    </div>
  );
};

export default Login;
