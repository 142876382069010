// src/firebase/customerFirebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';

let customerApp = null;

export const initializeCustomerFirebase = (config) => {
  if (!customerApp) {
    customerApp = initializeApp(config, 'customerApp');
  }
  return {
    customerDb: getFirestore(customerApp),
    customerRealtimeDb: getDatabase(customerApp)
  };
};
