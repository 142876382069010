import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { ref, onValue } from 'firebase/database';
import { initializeCustomerFirebase } from '../firebase/customerFirebase';
import { memberDb } from '../firebase/memberFirebase';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editableNames, setEditableNames] = useState({});
  const [editingField, setEditingField] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser) {
        navigate('/login');
        return;
      }

      try {
        const userDocRef = doc(memberDb, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          console.error('Kullanıcı belgesi bulunamadı.');
          navigate('/setup');
          return;
        }

        const userData = userDoc.data();

        if (!userData.setupCompleted || !userData.firebaseConfig) {
          console.error('Setup tamamlanmamış veya Firebase Config eksik.');
          navigate('/setup');
          return;
        }

        const { customerRealtimeDb, customerDb } = initializeCustomerFirebase(
          userData.firebaseConfig
        );

        const rootRef = ref(customerRealtimeDb);
        onValue(
          rootRef,
          async (snapshot) => {
            const data = snapshot.val();
            if (data) {
              const dataList = Object.entries(data).map(([key, value]) => ({
                id: key,
                ...value,
              }));
              setDataList(dataList);

              // Görünür isimleri kontrol et veya oluştur
              const namesDocRef = doc(customerDb, 'nodeNames', 'dashboard');
              const namesDoc = await getDoc(namesDocRef);
              if (!namesDoc.exists()) {
                const defaultNames = {};
                dataList.forEach((item) => {
                  defaultNames[item.id] = {
                    name: item.name || item.id,
                    description: item.description || '',
                  };
                });
                await setDoc(namesDocRef, defaultNames);
                setEditableNames(defaultNames);
              } else {
                setEditableNames(namesDoc.data());
              }
            } else {
              setDataList([]);
            }
            setLoading(false);
          },
          (error) => {
            console.error('Realtime Database Okuma Hatası:', error);
            setLoading(false);
          }
        );
      } catch (error) {
        console.error('Hata:', error);
        setLoading(false);
        navigate('/setup');
      }
    };

    fetchData();
  }, [currentUser, navigate]);

  const handleNameChange = async (key, field, newValue) => {
    try {
      const userDocRef = doc(memberDb, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.data();
      const { customerDb } = initializeCustomerFirebase(userData.firebaseConfig);

      const namesDocRef = doc(customerDb, 'nodeNames', 'dashboard');

      await updateDoc(namesDocRef, {
        [`${key}.${field}`]: newValue,
      });

      setEditableNames((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          [field]: newValue,
        },
      }));
    } catch (error) {
      console.error('Ad veya Açıklama Güncelleme Hatası:', error);
    }
  };

  const calculateTotalForNode = (nodeData) => {
    if (!nodeData) return 0;
    return Object.values(nodeData)
      .filter((value) => typeof value === 'object')
      .reduce(
        (sum, peron) =>
          sum +
          Object.values(peron)
            .filter((inputValue) => typeof inputValue === 'number')
            .reduce((acc, value) => acc + value, 0),
        0
      );
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p>Veriler yükleniyor, lütfen bekleyin...</p>
      </div>
    );
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Hoş Geldiniz, {currentUser?.email}</h1>

      {dataList.length === 0 ? (
        <p>Hiçbir veri bulunamadı.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {dataList.map((data) => (
            <div key={data.id} className="p-6 bg-white rounded-lg shadow-md">
              {/* Düzenlenebilir Başlık */}
              {editingField === `${data.id}-name` ? (
                <input
                  type="text"
                  className="text-xl font-bold mb-2 border border-gray-300 rounded p-1 w-full"
                  defaultValue={editableNames[data.id]?.name || data.id}
                  autoFocus
                  onBlur={(e) =>
                    handleNameChange(data.id, 'name', e.target.value.trim())
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleNameChange(data.id, 'name', e.target.value.trim());
                      setEditingField(null);
                    }
                  }}
                />
              ) : (
                <h2
                  className="text-xl font-bold mb-2 text-gray-800 cursor-pointer"
                  onDoubleClick={() => setEditingField(`${data.id}-name`)}
                >
                  {editableNames[data.id]?.name || data.id}
                </h2>
              )}

              {/* Düzenlenebilir Açıklama */}
              {editingField === `${data.id}-description` ? (
                <textarea
                  className="text-gray-700 mb-2 border border-gray-300 rounded p-2 w-full"
                  defaultValue={editableNames[data.id]?.description || ''}
                  autoFocus
                  onBlur={(e) =>
                    handleNameChange(data.id, 'description', e.target.value.trim())
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleNameChange(data.id, 'description', e.target.value.trim());
                      setEditingField(null);
                    }
                  }}
                />
              ) : (
                <p
                  className="text-gray-700 cursor-pointer"
                  onDoubleClick={() => setEditingField(`${data.id}-description`)}
                >
                  {editableNames[data.id]?.description || 'Açıklama yok'}
                </p>
              )}

              {/* Toplam Peron Verisi */}
              <p className="text-lg font-semibold text-gray-600">
                Toplam Peron Değeri: {calculateTotalForNode(data)}
              </p>

              <button
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={() => navigate(`/details/${data.id}`)}
              >
                Detayları Görüntüle
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
