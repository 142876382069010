// src/components/Footer.jsx
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white shadow-md text-center p-4">
      <span className="text-sm text-gray-600">
        © 2024 Dashboard Site. Tüm hakları saklıdır.
      </span>
    </footer>
  );
};

export default Footer;